import $ from "jquery"
window.$ = require('jquery')
window.jQuery = window.$

import "jquery\.scrollto"
import "bootstrap/js/src/scrollspy"
import "bootstrap/js/src/collapse"
import "bootstrap/js/src/dropdown"

import "sync-scroll"

// Scnyscroll
if ($(window).width() >= 576) {
    $('div[name ="syncscroll"]').addClass('syncscroll');
}

// Scrollspy
$('body').scrollspy({target: '#indigo-nav'})

// Page navigation scrolling
$('[data-scrollTo]').click(function (e) {
    e.preventDefault()
    const offset = $(this.hash).offset().top
    const duration = 300 + Math.round(Math.pow(Math.log(offset), 3))
    $(window).stop(true).scrollTo(this.hash, {
        duration: duration,
        interrupt: true,
        offset: 0
    })
})
